
import M_information from '../modals/QuotesToDo/Information';

import C_Database from '@js/controllers/Database';
import C_Quote from '../controllers/AddQuotes';

import moment from 'moment';

import { GetContextMenuItemsParams, MenuItemDef } from '@ag-grid-enterprise/all-modules';

import Utils from '@libs/utils/Utils';

// MANAGER

import { Config as ConfigFilter } from '@libs/customElement/FilterButton';

class QuoteToDo extends C_Database {
	constructor(el: HTMLElement) {
		super(el, {
			name: 'Devis à faire',
			type: 'database',
			database: 'quotes-to-do'
		});

		const N_add = document.querySelector('#add') as HTMLButtonElement;

		N_add.classList.remove('d-none');

		N_add.addEventListener('click', () => {
			new M_information().open().then(() => {
				this.refreshData();
			});
		}, {
			signal: this.abortSignal
		});
	}

	protected buttonEdit(params: any) {
		new M_information(params.data._id).open().then(() => {
			this.refreshData();
		});
	}

	protected addOnButtonGrid(params: any) {
		const N_quote = document.createElement('button');

		N_quote.classList.add('btn-transparent');

		N_quote.setAttribute('permission', 'QUOTES.ADD');

		N_quote.innerHTML = '<i class="h5 icon icon-file"></i>';

		if (params.data.quote) {
			N_quote.innerHTML = '<i class="h5 icon icon-solid-file"></i>';

			N_quote.setAttribute('tooltip', 'Ouvrir le devis n°' + params.data.quote.number);

			N_quote.addEventListener('click', () => {
				C_Quote.open(params.data.quote._id);
			});
		} else {
			N_quote.setAttribute('tooltip', 'Créer un devis');

			N_quote.addEventListener('click', () => {
				C_Quote.open(null, { quote_to_do: params.data._id });
			});
		}

		return [N_quote];
	}

	protected getContextMenu(params: GetContextMenuItemsParams): MenuItemDef[] {
		const result: any[] = [];

		if (params.node) {
			const quote = params.node.data.quote;

			if (quote._id) {
				result.push({
					name: `Voir le devis (N° ${quote.infos.number})`,
					icon: '<i class="icon icon-solid-file"></i>',
					action: () => {
						C_Quote.open(quote._id);
					}
				});
			} else {
				result.push({
					name: 'Créer un devis',
					icon: '<i class="icon icon-file"></i>',
					action: () => {
						C_Quote.open(null, { quote_to_do: params.node.data._id });
					}
				});
			}

			return [
				...super.getContextMenu(params),
				'separator',
				...result
			];
		} else {
			return super.getContextMenu(params);
		}
	}

	protected getRowStyle(params: any): { [key: string]: string } {
		//A relancer
		if (!params.data.finish && moment(params.data.limitDate, 'YYYY-MM-DD').isBefore(moment(), 'day')) {
			return {
				'background-color': 'var(--ap-blue-50)',
				color: 'var(--ap-blue-900)'
			};
		}
		return {};
	}

	/**
	 * Récupère la configuration pour les filtres externes
	 */
	protected get configFilter(): ConfigFilter {
		return [
			[
				{
					column: '_createBy_',
					type: 'switch',
					filters: [
						{
							value: Utils.userID,
							text: 'Devis personnel'
						}
					]
				}, {
					type: 'separator'
				}, {
					column: 'finish',
					type: 'switch-3',
					filters: [
						{
							values: ['false', 'true'],
							text: 'Traité'
						}
					]
				}, {
					type: 'separator'
				}, {
					column: 'urgent',
					type: 'switch',
					filters: [
						{
							value: 'true',
							text: 'Urgent'
						}
					]
				}, {
					type: 'separator'
				}, {
					column: 'isLate',
					type: 'switch',
					filters: [
						{
							value: 'true',
							text: 'En retard',
							color: 'light-blue'
						}
					]
				}
			]
		];
	}
}

export default QuoteToDo;
