
import { Form } from '@autoprog/core-client';

import Modal from '@libs/Modal';

import T_modal from '../../../tpl/modals/receipts/ContentEdit.html';

import S_Product from '@services/Product/ProductService';

import CE_Select2 from '@libs/customElement/Select2';

class ContentEdit extends Modal {
	private selectPostinit: { [key: string]: CE_Select2 } = {};

	private locations: any[] = [];

	constructor(item: { [key: string]: any }, locations: any[]) {
		super({
			tpl: T_modal,
			keyboard: false,
			backdrop: 'static'
		});

		this.locations = locations;

		this.on('opened', async () => {
			this.init();

			const form = new Form(this.element.querySelector('form') as HTMLFormElement);

			form.setData({
				...item,
				reference: await S_Product.getInstance().getDataToSelect2ByID(item.reference)
			});

			this.postInit();

			const N_save = this.element.querySelector('#save');

			N_save?.addEventListener('click', () => {
				this.resolve(form.getData());
			});
		});
	}

	private init() {
		const N_product = this.element.querySelector('[name="reference"]') as CE_Select2;

		N_product.create(this.element, { disabled: true });

		const N_locations = this.element.querySelector('#locations') as HTMLElement;

		for (const item of this.locations) {
			N_locations.innerHTML = `
				<div class="form-group row">
					<label class="col-form-label col-lg-2">${item.name} :</label>
					<div class="col-lg-10">
						<input class="form-control" type="number" name="stocks.${item._id}.quantity">
					</div>
				</div> 
			`;
		}

		this.selectPostinit.product = N_product;
	}

	private postInit() {
		this.selectPostinit.product.postInit();
	}
}

export default ContentEdit;
