
import PrintOptionTab from '@libs/customElement/PrintOptionTab';

import T_FormPrintOptions from '../../../tpl/blocks/deliveryFormPrintOption.ejs';

class PrintOptionDeliveries extends PrintOptionTab {
	public static readonly tagName: string = 'ap-deliveries-print-options-tab';

	protected readonly KEY_SETTINGS = '';

	protected eventChangeForm = 'options.**.change';

	constructor() {
		super();
		this.table = 'deliveries';
	}

	protected getFormTemplate() {
		return T_FormPrintOptions({});
	}

	protected setDefaultValue(data: { [key: string]: any }) {
		this.data = { options: data };
	}

	public static register() {
		customElements.define(this.tagName, PrintOptionDeliveries);
	}
}

export default PrintOptionDeliveries;
