
import GenericService from '../GenericService';
class CustomerContactService extends GenericService {
	private static instance: CustomerContactService | null = null;

	constructor() {
		super('customers-contacts');
	}

	public static getInstance(): CustomerContactService {
		if (!CustomerContactService.instance) {
			CustomerContactService.instance = new CustomerContactService();
		}

		return CustomerContactService.instance;
	}

	public async getByCustomerToSelect2(id_customer: string) {
		return this.searchByPattern('', { id_customer });
	}
}

export default CustomerContactService;
