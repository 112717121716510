
import { Alert, toaster } from '@autoprog/core-client';

import C_Database from '@js/controllers/Database';

import C_Delivery from '../controllers/Delivery';

import M_SelectTransporter, { EditMode } from '../modals/deliveries/editPage/SelectTransporter';

import S_Delivery from '@services/DeliveryService';

import { Config as ConfigFilter } from '@libs/customElement/FilterButton';
import { GetContextMenuItemsParams } from '@ag-grid-enterprise/all-modules';

import _ from 'lodash';

class DeliveriesTab extends C_Database {
	constructor(el: HTMLElement) {
		super(el, {
			name: 'Livraisons',
			database: 'deliveries'
		});

		const N_addBtn = document.querySelector('#add') as HTMLButtonElement;

		N_addBtn.addEventListener('click', () => {
			this.buttonEdit();
		}, {
			signal: this.abortSignal
		});
	}

	protected buttonEdit(params?: any) {
		C_Delivery.open(params?.data._id);
	}

	protected getContextMenu(params: GetContextMenuItemsParams) {
		const col = _.find(this.config.columns, { key: 'state' }) as any;

		const subMenu = [];

		for (const key in col.object) {
			subMenu.push({
				name: col.object[key],
				disabled: !(Number(params.node.data.state) - 1 === Number(key) || Number(params.node.data.state) + 1 === Number(key)),
				icon: params.node?.data.state === key ? '<i class="icon icon-solid-check"></i>' : '',
				action: async () => {
					if (key === '2') {
						const transporterData = {
							infos: {
								transporter: params.node.data.infos.transporterID,
								trackingNumber: params.node.data.infos.trackingNumber,
								commentsTransporter: params.node.data.infos.commentsTransporter
							}
						};
						new M_SelectTransporter(transporterData, EditMode.Dashboard).open().then(async (deliveryData) => {
							//Sauvegarde du bon de livraison
							await S_Delivery.getInstance().save({ _id: params.node.data._id, ...deliveryData }, { type: 'transporter' });
							toaster.success('Changement d\'état sauvegardé');
							this.refreshData();
						});
					} else {
						Alert.confirm('Confirmation', `Voulez vous changer l'état du BL n° ${params.node.data.infos.number} ?`).then(async () => {
							await S_Delivery.getInstance().save({ _id: params.node.data._id, state: key });
							toaster.success('Changement d\'état sauvegardé');
							this.refreshData();
						});
					}
				}
			});
		}

		return [
			...super.getContextMenu(params),
			'separator',
			{
				name: 'Modification État BL',
				icon: '<i class="icon icon-solid-exchange-alt"></i>',
				disabled: !params.node,
				subMenu
			}
		];
	}

	protected getRowStyle(params: any): { [key: string]: string } {
		if (params.node.data.state === '2') {
			return {
				'background-color': 'var(--ap-green-50)',
				color: 'var(--ap-green-900)'
			};
		}

		if (params.node.data.state === '0') {
			return {
				'background-color': 'var(--ap-red-50)',
				color: 'var(--ap-red-900)'
			};
		}

		if (params.node.data.state === '1') {
			return {
				'background-color': 'var(--ap-orange-50)',
				color: 'var(--ap-orange-900)'
			};
		}

		return {
			'background-color': 'white'
		};
	}

	protected get configFilter(): ConfigFilter {
		return [
			[
				{
					type: 'title',
					title: 'État : '
				}, {
					column: 'state',
					type: 'checkbox',
					filters: [{
						value: '0',
						text: 'En cours de préparation',
						color: 'red-200'
					}, {
						value: '1',
						text: 'Prête à expédier',
						color: 'orange-200'
					}, {
						value: '2',
						text: 'Expédiée',
						color: 'green-200'
					}]
				}
			]
		];
	}
}

export default DeliveriesTab;
