
import GenericService from '../GenericService';

class ProviderContactService extends GenericService {
	private static instance: ProviderContactService | null = null;

	constructor() {
		super('providers-contacts');
	}

	public static getInstance(): ProviderContactService {
		if (!ProviderContactService.instance) {
			ProviderContactService.instance = new ProviderContactService();
		}

		return ProviderContactService.instance;
	}

	public async getByProviderToSelect2(id_provider: string) {
		return this.searchByPattern('', { id_provider });
	}
}

export default ProviderContactService;
