
class Quantity extends HTMLElement {
	public static readonly tagName: string = 'ap-quantity';

	private disabled: boolean = false;

	public async connectedCallback() {
		const name = this.getAttribute('name') as string;

		this.classList.add('w-100');

		this.innerHTML = `
			<div class="input-group">
				<input type="number" class="form-control" name="${name}" value="0">
				<div class="input-group-append">
					<button class="btn btn-info ml-1" id="add_quantity" type="button">
						<i class="icon icon-solid-plus"></i>
					</button>
					<button class="btn btn-danger ml-1" id="remove_quantity" type="button">
						<i class="icon icon-solid-minus"></i>
					</button>
				</div>
			</div>
        `;

		//Bouton quantité
		const N_add = this.querySelector('#add_quantity') as HTMLButtonElement;
		const N_remove = this.querySelector('#remove_quantity') as HTMLButtonElement;

		const N_input = this.querySelector('input') as HTMLInputElement;

		N_add.addEventListener('click', () => {
			let quantity = (N_input.valueAsNumber as number || 0);

			quantity++;

			if (quantity < 0) {
				quantity = 0;
			}

			N_input.valueAsNumber = quantity;

			this.dispatchEvent(new Event('change', { bubbles: true }));
			N_input.dispatchEvent(new Event('change', { bubbles: true }));
		});

		N_remove.addEventListener('click', () => {
			let quantity = (N_input.valueAsNumber as number || 0);

			quantity--;

			if (quantity < 0) {
				quantity = 0;
			}

			N_input.valueAsNumber = quantity;

			this.dispatchEvent(new Event('change', { bubbles: true }));
			N_input.dispatchEvent(new Event('change', { bubbles: true }));
		});

		N_input.addEventListener('input', () => {
			this.dispatchEvent(new Event('change', { bubbles: true }));
		});

		N_input.addEventListener('change', () => {
			this.dispatchEvent(new Event('change', { bubbles: true }));
		});

		const instance = this;

		Object.defineProperty(N_input, 'disabled', {
			set: function (disabled) {
				N_add.disabled = disabled;
				N_remove.disabled = disabled;
				if (disabled) {
					N_input.setAttribute('disabled', disabled);
				} else {
					N_input.removeAttribute('disabled');
				}

				instance.disabled = disabled;
			},
			get: function () {
				return instance.disabled;
			}
		});

		this.removeAttribute('name');
	}

	public static register() {
		customElements.define(Quantity.tagName, Quantity);
	}
}

export default Quantity;
