
// CORE
import { Form } from '@autoprog/core-client';

// NODE_MODULE
// TEMPLATE
import T_modal from '../../tpl/modals/selectCustomerToPrint.html';
// LIBS
import Modal from '@libs/Modal';

// PRINTER

// UTILS
// MODAL
import M_PrintPreview from '@libs/modals/PrintPreview';

// CUSTOM_ELEMENT
import CE_Select2 from '@libs/customElement/Select2';

// SERVICE
import S_C_Bill from '@services/Customer/CustomerBillService';

class SelectCustomerToPrint extends Modal {
	private data: any[] = [];

	constructor() {
		super({
			tpl: T_modal,
			keyboard: false,
			backdrop: 'static'
		});

		this.on('opened', () => {
			const N_customer = this.element.querySelector('[name="customer"]') as CE_Select2;
			const N_number_delay = this.element.querySelector('#number_delay') as HTMLElement;
			const N_save = this.element.querySelector('#save') as HTMLButtonElement;

			const form = new Form(this.element.querySelector('form')!);

			N_customer.create(this.element);

			N_customer.on('change', async () => {
				this.data = await S_C_Bill.getInstance().getCurrentByCustomer(form.getDataByName('customer') as string);

				N_number_delay.innerHTML = this.data.length.toString();

				N_save.disabled = this.data.length === 0;
			});

			N_customer.postInit();

			N_save.addEventListener('click', async () => {
				(await new M_PrintPreview('bills', '', 'pdfListByCustomer').getToken(this.data)).open();
				this.resolve();
			});
		});
	}
}

export default SelectCustomerToPrint;
