
class DeadlinePayment extends HTMLElement {
	public static readonly tagName: string = 'ap-deadline-payment-readonly';

	private deadlinePayment = '';
	private typeDeadlinePayment = '';
	private fixedDateDeadline = '';

	public async connectedCallback() {
		const nameDeadlinePayment = this.getAttribute('name-deadlinePayment') || '';
		const nameTypeDeadlinePayment = this.getAttribute('name-typeDeadlinePayment') || '';
		const nameFixedDateDeadline = this.getAttribute('name-fixedDateDeadline') || '';
		const classText = this.getAttribute('class-text');

		this.innerHTML = `
			<div class="w-100 d-flex align-items-center">
				<div id="value" class="${classText} page-card-value"></div>
				<div class="d-none" name="${nameDeadlinePayment}"></div>
				<div class="d-none" name="${nameTypeDeadlinePayment}"></div>
				<div class="d-none" name="${nameFixedDateDeadline}"></div>
			</div>
        `;

		const N_typeDeadlinePayment = this.querySelector(`[name="${nameTypeDeadlinePayment}"]`) as HTMLElement;
		const N_fixedDateDeadline = this.querySelector(`[name="${nameFixedDateDeadline}"]`) as HTMLElement;
		const N_deadlinePayment = this.querySelector(`[name="${nameDeadlinePayment}"]`) as HTMLElement;

		const instance = this;

		Object.defineProperty(N_typeDeadlinePayment, 'innerHTML', {
			set: function (value) {
				instance.typeDeadlinePayment = value;
				instance.update();
			}
		});

		Object.defineProperty(N_fixedDateDeadline, 'innerHTML', {
			set: function (value) {
				instance.fixedDateDeadline = value;
				instance.update();
			}
		});

		Object.defineProperty(N_deadlinePayment, 'innerHTML', {
			set: function (value) {
				instance.deadlinePayment = value;
				instance.update();
			}
		});
	}

	private update() {
		const N_value = this.querySelector('#value') as HTMLElement;

		const type: { [key: string]: string } = {
			1: 'Fin de mois',
			2: 'Date facture',
			3: 'A réception de facture'
		};

		const suffix = (this.typeDeadlinePayment === '1' && this.fixedDateDeadline !== '0') ? `le ${this.fixedDateDeadline}` : '';

		N_value.innerHTML = `${this.deadlinePayment} J ${type[this.typeDeadlinePayment]} ${suffix}`;
	}

	public static register() {
		customElements.define(DeadlinePayment.tagName, DeadlinePayment);
	}
}

export default DeadlinePayment;
