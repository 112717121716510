
// eslint-disable-next-line import/no-webpack-loader-syntax
import T_keyboard from '!!raw-loader!@tpl/blocks/keyboard/index.svg';
// eslint-disable-next-line import/no-webpack-loader-syntax
import T_middle from '!!raw-loader!@tpl/blocks/keyboard/middle.svg';
// eslint-disable-next-line import/no-webpack-loader-syntax
import T_number from '!!raw-loader!@tpl/blocks/keyboard/number.svg';

import EventEmitter from '@autoprog/eventemitter';
import { LoggedUser } from '@autoprog/core-client';

class Keyboard extends EventEmitter {
	private static enable = true;
	private static event = new EventEmitter();

	private N_input: HTMLInputElement;

	private N_container: null | HTMLElement = null;

	private isOpen = false;

	private isCapsLock = false;
	private isShift = false;
	private isAltGr = false;

	private cancellers: EventListenerCanceller[] = [];

	private abortController: AbortController;
	protected get abortSignal(): AbortSignal {
		return this.abortController.signal;
	}

	private options: { [key: string]: boolean | string | HTMLElement } = {
		disabledArrow: true,
		disabledInserBlock: true,
		disabledScreenBlock: true,
		disabledKeypad: false,
		disabledKeyboard: false,
		eventClick: 'click',
		parent: document.body
	};

	constructor(input: HTMLInputElement, options: { [key: string]: boolean | string | HTMLElement } = {}) {
		super();

		this.N_input = input;

		this.options = {
			...this.options,
			...options
		};

		this.abortController = new AbortController();

		this.N_input.addEventListener(this.options.eventClick as string, () => {
			if (Keyboard.enable) {
				this.open();
			}
		});

		LoggedUser.getInstance().on('logout', () => {
			this.close();
		});

		Keyboard.event.on('change', (value: boolean) => {
			if (!value) {
				this.close();
			}
		});
	}

	public static init() {
		const N_div = document.createElement('div');

		N_div.classList.add('position-absolute', 'bg-grey-200', 'm-2', 'p-2', 'rounded');

		N_div.style.bottom = '0';
		N_div.style.left = '0';
		N_div.style.zIndex = '9999999';

		N_div.innerHTML = `		
			<div class="cursor-pointer d-flex align-items-center">
				<label class="switch ml-auto">
					<input type="checkbox" id="enable">
					<span class="slider round"></span>
				</label>
				<div class="custom_h4 ml-2">Clavier virtuel</div>
			</div>
		`;

		const N_enable = N_div.querySelector('#enable') as HTMLInputElement;

		N_enable.checked = this.enable;

		N_enable.addEventListener('change', () => {
			this.enable = N_enable.checked;
			this.event.emit('change', this.enable);
		});

		document.body.appendChild(N_div);
	}

	public open() {
		if (!this.isOpen) {
			const body = this.options.parent as HTMLElement;

			document.body.addEventListener('keydown', (e: KeyboardEvent) => {
				if (e.keyCode === 27) {
					this.close();

					e.preventDefault();
				}
			}, {
				signal: this.abortSignal
			});

			this.N_input.addEventListener('keydown', (e: KeyboardEvent) => {
				if (e.keyCode === 27) {
					this.close();

					e.preventDefault();
				}
			}, {
				signal: this.abortSignal
			});

			this.N_input.focus();

			body.style.position = 'relative';

			this.N_container = document.createElement('div');

			this.N_container.style.width = '100%';
			this.N_container.style.height = '100%';
			this.N_container.style.textAlign = 'center';
			this.N_container.style.background = 'white';
			this.N_container.style.userSelect = 'none';

			this.N_container.innerHTML = `
                ${!this.options.disabledKeyboard ? T_keyboard : ''}
                ${!this.options.disabledArrow || !this.options.disabledInserBlock || !this.options.disabledScreenBlock ? T_middle : ''}
                ${!this.options.disabledKeypad ? T_number : ''}
            `;

			this.initEvent();

			body.appendChild(this.N_container);

			for (const key in this.options) {
				if (this.options[key]) {
					const N_el = this.N_container.querySelector(`[data-options="${key}"]`);

					N_el?.remove();
				}
			}

			this.updateEnable('normal');

			this.isOpen = true;

			this.emit('opened');
		}
	}

	public close() {
		this.N_container?.remove();
		this.isOpen = false;

		this.abortController.abort('destroyed');

		for (const canceller of this.cancellers) {
			canceller();
		}

		this.emit('closed');
	}

	private eventData() {
		const event = document.createEvent('Event');
		event.initEvent('input', true, true);
		this.N_input.dispatchEvent(event);
	}

	private initEvent() {
		if (this.N_container) {
			const N_normal = this.N_container.querySelectorAll('[data-normal]') as NodeListOf<HTMLElement>;

			for (const el of N_normal) {
				el.addEventListener(this.options.eventClick as string, (e) => {
					if (!this.isAltGr && ((!this.isCapsLock && !this.isShift) || (this.isCapsLock && this.isShift))) {
						this.N_input.value += el.dataset.normal || '';

						this.eventData();

						if (this.isShift && this.N_container) {
							this.isShift = false;

							const N_shift = this.N_container.querySelectorAll('[data-key="shift"]') as NodeListOf<HTMLElement>;

							for (const el of N_shift) {
								const N_rect = el.querySelector('rect') as SVGRectElement;

								N_rect.style.fill = this.isShift ? '#2196f3' : '#dbe2e3';
							}
						}

						if (this.isCapsLock) {
							this.updateEnable('shift');
						} else {
							this.updateEnable('normal');
						}

						e.preventDefault();
						e.stopPropagation();
						e.stopImmediatePropagation();
					}
				});
			}

			const N_shift = this.N_container.querySelectorAll('[data-shift]') as NodeListOf<HTMLElement>;

			for (const el of N_shift) {
				el.addEventListener(this.options.eventClick as string, (e) => {
					if (!this.isAltGr && ((this.isCapsLock && !this.isShift) || (!this.isCapsLock && this.isShift))) {
						this.N_input.value += el.dataset.shift || '';

						this.eventData();

						if (this.isShift && this.N_container) {
							this.isShift = false;

							const N_shift = this.N_container.querySelectorAll('[data-key="shift"]') as NodeListOf<HTMLElement>;

							for (const el of N_shift) {
								const N_rect = el.querySelector('rect') as SVGRectElement;

								N_rect.style.fill = this.isShift ? '#2196f3' : '#dbe2e3';
							}
						}

						if (this.isCapsLock) {
							this.updateEnable('shift');
						} else {
							this.updateEnable('normal');
						}

						e.preventDefault();
						e.stopPropagation();
					}
				});
			}

			const N_altGr = this.N_container.querySelectorAll('[data-altGr]') as NodeListOf<HTMLElement>;

			for (const el of N_altGr) {
				el.addEventListener(this.options.eventClick as string, (e) => {
					if (this.isAltGr && this.N_container) {
						this.N_input.value += el.dataset.altGr || '';

						this.eventData();
						this.isAltGr = false;

						const N_altGr = this.N_container.querySelectorAll('[data-key="altgr"]') as NodeListOf<HTMLElement>;

						for (const el of N_altGr) {
							const N_rect = el.querySelector('rect') as SVGRectElement;

							N_rect.style.fill = this.isShift ? '#2196f3' : '#dbe2e3';
						}

						e.preventDefault();
						e.stopPropagation();
					}
				});
			}

			const N_keypad = this.N_container.querySelectorAll('[data-keypad]') as NodeListOf<HTMLElement>;

			for (const el of N_keypad) {
				el.addEventListener(this.options.eventClick as string, (e) => {
					this.N_input.value += el.dataset.keypad || '';

					this.eventData();

					e.preventDefault();
					e.stopPropagation();
				});
			}

			const N_key = this.N_container.querySelectorAll('[data-key]') as NodeListOf<HTMLElement>;

			for (const el of N_key) {
				el.addEventListener(this.options.eventClick as string, () => {
					if (this.N_container) {
						const key = el.dataset.key || '';

						if (key === 'shift') {
							this.isShift = !this.isShift;

							if (this.isCapsLock) {
								this.updateEnable(!this.isShift ? 'shift' : 'normal');
							} else {
								this.updateEnable(this.isShift ? 'shift' : 'normal');
							}

							const N_shift = this.N_container.querySelectorAll('[data-key="shift"]') as NodeListOf<HTMLElement>;

							for (const el of N_shift) {
								const N_rect = el.querySelector('rect') as SVGRectElement;

								N_rect.style.fill = this.isShift ? '#2196f3' : '#dbe2e3';
							}
						}

						if (key === 'altgr') {
							this.isAltGr = !this.isAltGr;
							this.updateEnable(this.isAltGr ? 'altgr' : 'normal');

							const N_altgr = this.N_container.querySelectorAll('[data-key="altgr"]') as NodeListOf<HTMLElement>;

							for (const el of N_altgr) {
								const N_rect = el.querySelector('rect') as SVGRectElement;

								N_rect.style.fill = this.isAltGr ? '#2196f3' : '#dbe2e3';
							}
						}

						if (key === 'capslock') {
							this.isCapsLock = !this.isCapsLock;
							this.updateEnable(this.isCapsLock ? 'shift' : 'normal');

							const N_lightCapslock = this.N_container.querySelectorAll('[data-light="capslock"]') as NodeListOf<HTMLElement>;

							for (const el of N_lightCapslock) {
								el.style.fill = this.isCapsLock ? '#25bb2d' : '#FFFFFF';
							}
						}

						if (key === 'backspace') {
							this.N_input.value = this.N_input.value.slice(0, -1);
							this.eventData();
						}

						if (key === 'escape') {
							this.close();
						}

						if (key === 'space') {
							this.N_input.value += ' ';
							this.eventData();
						}

						if (key === 'enter' && this.N_input.tagName === 'TEXTAREA') {
							this.N_input.value += '\n';
							this.eventData();
						}
					}
				});
			}
		}
	}

	private updateEnable(type: string) {
		if (this.N_container) {
			const N_el = this.N_container.querySelectorAll('[data-enable]') as NodeListOf<HTMLElement>;

			for (const el of N_el) {
				if (el.dataset.enable === type) {
					el.classList.remove('d-none');

					el.style.fill = '#000';
				} else {
					if (el.dataset.hidden === '1' && type !== 'altgr') {
						el.classList.add('d-none');
					} else {
						el.style.fill = '#CCC';
					}
				}
			}
		}
	}
}

export default Keyboard;
